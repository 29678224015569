@import "primeng/resources/themes/viva-light/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

:root {
    --app-primary-color: #30336b;
    --app-primary-hover-color: #3c4a85;
    --app-text: #030001;
}

body {
    font-family: 'Poppins', sans-serif;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    background-color: #F1F2F6;
}

.primary-button {
    border-color: var(--app-primary-color);
    background-color: var(--app-primary-color);
}

.primary-button:hover {
    border-color: var(--app-primary-hover-color);
    background-color: var(--app-primary-hover-color);
}

input[type="checkbox"] {
    background-color: var(--app-primary-color) !important;
}

::ng-deep .p-button-icon-only {
  border-color: var(--app-primary-color) !important;
  background-color: var(--app-primary-color) !important;
}

::ng-deep .p-button-icon-only:hover {
  border-color: var(--app-primary-hover-color) !important;
  background-color: var(--app-primary-hover-color) !important;
}
